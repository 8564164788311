<template>
<v-app>

   <div class="input_form_box">
  <!-- <input v-model="newtask" placeholder="input new task"> -->
  
  <v-form>
    <v-text-field
      v-model="newtask"
      :rules="formRules"
      placeholder="Input New Task"
      >
    </v-text-field>
  </v-form>
    <v-icon @click="submit()">mdi-send</v-icon>

  </div>


  <div class="main_box">

  <h1>Your Tasks</h1>
<!-- 
  <v-avatar
  class="avatar"
  color="primary"
  size="56"
>SK</v-avatar> -->

 

  <!-- <h2>email : {{$store.state.email}}</h2>
  <h2>user id : {{$store.state.userId}}</h2> -->

  <ul class="ul_class">
    <li v-for="task in tasks" :key="task._id" >
      <div class="task_one_item">
        <div v-bind:class="[task.completed ? completedClass : '']">{{task.name}}</div>

        <v-spacer></v-spacer>

        <v-icon style="align : right;" @click="edit_item(task._id)">mdi-file-document-edit-outline</v-icon>
        <v-icon style="align : right;" @click="delete_item(task._id)">mdi-delete</v-icon>
        <v-icon style="align : right;" @click="completed_item(task)">mdi-checkbox-marked-outline</v-icon>
        
      </div>
    </li>
    
  </ul>

  </div>

   
</v-app>
</template>

<script>

export default {
  name: 'Todo',

  components: {

  },

  data() {
  
    return {
      newtask : "",
      tasks : [
        { name : "study english"},
        { name : "study vue.js"},
        { name : "study node.js"}],
      a : "",
      error : "",
      formRules: [
        v => !!v || 'Task is required',
        v => v.length <= 15 || 'Task must be less than 15 characters',
      ],


      completedClass : 'completed'
    
    }
  },

  mounted () {

    console.log(process.env.VUE_APP_APIURL)

    // get token
    const token = this.$cookies.get('jwt')

    // redirect to home
    if(!token) {
      this.$router.push('/', () => {})
    }

    console.log("this is jwt", token)

    this.axios.get( process.env.VUE_APP_APIURL + '/api/v1/tasks',  {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { hello : "hello"}
    })
      .then((response) => {
        this.tasks = response.data.tasks
        this.$store.state.email = response.data.user.email
        this.$store.state.userId = response.data.user._id
        console.log(response.data)
        // console.log(res)
      })
      .catch((e) => {
        console.log(e)
      })
  },

  // mounted

  methods: {

    submit()  {
      const token = this.$cookies.get('jwt')
      this.axios.post(process.env.VUE_APP_APIURL + '/api/v1/tasks', {name: this.newtask}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
      })
      .then((res) => {
        console.log(res)
        console.log(res.data)
        this.tasks.push(res.data.task)

      })
      .catch((e) => {
        this.message = e
      })
    },

    // edit item
    // delete item
    edit_item(id) {

      // this.$router.push({ name: 'edititem', params: {id: id} } );
      this.$router.push(`/todo/${id}`);


      // const token = this.$cookies.get('jwt')
      // this.axios.delete(process.env.VUE_APP_API_BASE + '/api/v1/tasks/' + id , {
      //   headers: {
      //   Authorization: `Bearer ${token}`
      // }
      // })
      // .then((res) => {
      //   this.tasks = this.tasks.filter((item) => item._id !== id);
      //   console.log(res)
      //   console.log(res.data.task._id)
      // })
      // .catch((e) => {
      //   this.message = e
      // })
    },
    

    // delete item
    delete_item(id) {
      const token = this.$cookies.get('jwt')
      this.axios.delete(process.env.VUE_APP_APIURL + '/api/v1/tasks/' + id , {
        headers: {
        Authorization: `Bearer ${token}`
      }
      })
      .then((res) => {
        this.tasks = this.tasks.filter((item) => item._id !== id);
        console.log(res)
        console.log(res.data.task._id)
      })
      .catch((e) => {
        this.message = e
      })
    },

    // delete item
    completed_item(task) {
      const token = this.$cookies.get('jwt')

      task.completed = !task.completed
      this.axios.patch(process.env.VUE_APP_APIURL + '/api/v1/tasks/' + task._id , {completed: task.completed}, {
        headers: {
        Authorization: `Bearer ${token}`
      }
      })
      .then((res) => {
        // this.tasks = this.tasks.filter((item) => item._id !== id);
        console.log(res)
        console.log(res.data.task._id)
      })
      .catch((e) => {
        this.message = e
      })
    }



  }

};
</script>

<style scoped>

body {
  background-color:   #aaa;
}

.input_form_box{
  /* border: solid 1px #000; */
  width: 80%;
  margin : 50px auto 0 auto;
  padding: 10px 20px;
  /* margin : 10px; */
  display : flex;
  border-radius: 5px;
  box-shadow: 0 0 8px gray;
}


.main_box {
  width : 80%;
  margin : 50px auto 0 auto;
  padding : 10px;
  /* border: solid 1px #000; */
  border-radius: 5px;
  box-shadow: 0 0 8px gray;
}


.completed {
  text-decoration: line-through;
}

.task_one_item {
  display: flex;
}

.ul_class {
  list-style: none;
  /* padding: 0;
  margin: 0; */
}
.avatar{
  color: white;
}

</style>
