<template>
<v-app>
  <v-card width="400px" class="mx-auto mt-5">
    <v-card-title>
      <h1 class="display-1">Log in</h1>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
        prepend-icon="mdi-account-circle"
        label="email"
        v-model="email"
        />
        <div class="error_message">{{errors.email}}</div>

        <v-text-field
        v-bind:type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        prepend-icon="mdi-lock"
        append-icon="mdi-eye-off"
        label="password"
        v-model="password"
         />
         <div class="error_message">{{errors.password}}</div>
         

        <v-card-actions>
           <v-btn class="info" @click="submit">Log in</v-btn> 
        </v-card-actions>

        <div>{{$store.state.userId}}</div>

      </v-form>
    </v-card-text>
  </v-card>

  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {

  },

  data() {
    return {
      showPassword : false,
      email: '',
      password: '',
      message: '',
      errors : {email : '', password : ''}
    }
  },

  mounted (){
    console.log("login show api base", process.env.VUE_APP_APIURL)
  },

  methods: {
    submit()  {
      
      console.log(process.env.VUE_APP_APIURL)
      console.log(this.email, this.password)

      this.axios.post( process.env.VUE_APP_APIURL + '/login', {email:this.email, password:this.password})
      .then((res) => {

        // console.log(res)

        // console.log(res.data)
        
        if(res.data.userId) {
        
          this.$store.state.userId = res.data.userId
          this.$store.state.email = res.data.email
          this.$store.state.login = true

          // this.$cookie.set("jwt", res.data.jwt , '3d')

          this.$cookies.set("jwt", res.data.jwt , "3d", "/")
          
          this.$router.push({ path: 'todo' });
          // this.$cookies.set("token","GH1.1.1689020474.1484362313","24d");
        }

        if(res.errors.email || res.errors.password) {
          this.errors.email = res.errors.email
          this.errors.password = res.errors.password
          
        }

        // console.log(this.message)
      })
      .catch((e) => {
        // console.log(e.response.data.errors)

        console.log(e.response)
        this.errors = e.response.data.errors

      })
    }
  }

};
</script>

<style scoped>

.error_message {
  color : #E57373;
  
}
</style>
