<template>
<v-app>

    <!-- <h1>{{ this.$route.params['id'] }}</h1> -->

    <!-- <h1>{{task}}</h1> -->
    <div class="main_box">
    <h1 class="title_edit">Edit Task</h1>

    <v-container>
      <!-- id -->
    <v-row>
      <v-col cols="12" md="4">
         <div>Task ID</div>
      </v-col>
      <v-col cols="12" md="8">
        {{task._id}}
      </v-col>
    </v-row>
     <!-- name -->
     <v-row class="item_row">
      <v-col cols="12" md="4">
        <div>Name</div>
      </v-col>
      <v-col cols="12" md="8" class="item_col">
        <v-text-field v-model="task.name"></v-text-field>
      </v-col>
    </v-row>
    <!-- completed -->
    <v-row class="item_row">
      <v-col cols="12" md="4">
        <div>Completed</div>
        
      </v-col>
      <v-col cols="12" md="8">
        <v-checkbox
          v-model="task.completed">
        </v-checkbox>
      </v-col>
    </v-row>

    <div class="submit_button">
    <v-btn color="primary" @click="submit()">Submit</v-btn>
    </div>
    </v-container>
  

    </div>

</v-app>
</template>

<script>

export default {
  name: 'Todo',

  components: {
  },

  data() {
    return {
        task : ""
    }
  },

  mounted () {

    console.log(process.env.VUE_APP_APIURL)

    // get token
    const token = this.$cookies.get('jwt')

    // redirect to home
    if(!token) {
      this.$router.push('/', () => {})
    }

    console.log("this is jwt", token)

    this.axios.get( process.env.VUE_APP_APIURL + `/api/v1/tasks/${this.$route.params['id'] }`,  {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { hello : "hello"}
    })
      .then((response) => {
          console.log(response)
          this.task = response.data.task
        // this.tasks = response.data.tasks
        // this.$store.state.email = response.data.user.email
        // this.$store.state.userId = response.data.user._id

        // console.log(response.data)
        // console.log(res)
      })
      .catch((e) => {
        console.log(e)
      })

  },

  // mounted

  methods: {

    submit()  {
      const token = this.$cookies.get('jwt')

      const editedTask = { completed : this.task.completed, name: this.task.name}
      console.log("edited task", editedTask)
      this.axios.patch( process.env.VUE_APP_APIURL + '/api/v1/tasks/' + this.task._id , editedTask , {
      headers: {
        Authorization: `Bearer ${token}`
      }
      })
      .then((res) => {
        console.log(res)
        // console.log(res.data)
        // this.tasks.push(res.data.task)

      })
      .catch((e) => {
        this.message = e
      })
    },

    // // delete item
    // delete_item(id) {
    //   const token = this.$cookies.get('jwt')
    //   this.axios.delete(process.env.VUE_APP_APIURL + '/api/v1/tasks/' + id , {
    //     headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    //   })
    //   .then((res) => {
    //     this.tasks = this.tasks.filter((item) => item._id !== id);
    //     console.log(res)
    //     console.log(res.data.task._id)
    //   })
    //   .catch((e) => {
    //     this.message = e
    //   })
    // },

    // // delete item
    // completed_item(task) {
    //   const token = this.$cookies.get('jwt')

    //   // task.completed = !task.completed
    //   this.axios.patch(process.env.VUE_APP_APIURL + '/api/v1/tasks/' + task._id , {completed: task.completed}, {
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   })
    //   .then((res) => {
    //     // this.tasks = this.tasks.filter((item) => item._id !== id);
    //     console.log(res)
    //     console.log(res.data.task._id)
    //   })
    //   .catch((e) => {
    //     this.message = e
    //   })
    // }



  }

};
</script>

<style scoped>


.title_edit {
  margin : 20px 0 20px 0;
  text-align: center;
}

.input_form_box{
  /* border: solid 1px #000; */
  width: 80%;
  margin : 50px auto 0 auto;
  padding: 10px 20px;
  /* margin : 10px; */
  display : flex;
  border-radius: 5px;
  box-shadow: 0 0 8px gray;
}

.task_propaty {
  font-size : 120%;
}

.task_name_propaty {
  display : flex;
}


.main_box {
  width : 80%;
  margin : 50px auto 0 auto;
  padding : 50px;
  /* border: solid 1px #000; */
  border-radius: 5px;
  box-shadow: 0 0 8px gray;
}

.item_row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.completed {
  text-decoration: line-through;
}

.task_one_item {
  display: flex;
}

.ul_class {
  list-style: none;
  /* padding: 0;
  margin: 0; */
}
.avatar{
  color: white;
}

.submit_button {
  margin : 30px 0 0 0;
  text-align: center;
}

</style>
