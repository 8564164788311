<template>
<v-app>
  <v-card width="400px" class="mx-auto mt-5">
    <v-card-title>
      <h1 class="display-1">Sign up</h1>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
        prepend-icon="mdi-account-circle"
        label="email"
        v-model="email"
        />
        
        <v-text-field
        v-bind:type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        prepend-icon="mdi-lock"
        append-icon="mdi-eye-off"
        label="password"
        v-model="password"
         />

        <v-card-actions>
           <v-btn class="info" @click="submit">Sign up</v-btn> 
        </v-card-actions>

        <div>{{$store.state.userId}}</div>

      </v-form>
    </v-card-text>
  </v-card>

   
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {

  },

  data() {
    return {
      showPassword : false,
      email: '',
      password: '',
      message: ''
    }
  },

  methods: {
    submit()  {
      console.log(this.email, this.password)
      this.axios.post(process.env.VUE_APP_APIURL + '/signup', {email:this.email, password:this.password})
      .then((res) => {

        console.log(res.data)

        // const token = this.$cookies.get('jwt')
        
        if(res.data.userId) {
        
          this.$store.state.userId = res.data.userId
          this.$store.state.email = res.data.email
          this.$store.state.login = true

          this.$cookies.set("jwt", res.data.jwt , "3d", "/")

          this.$router.push({ path: 'todo' });
        }
        console.log(this.message)
      })
      .catch((e) => {
        console.log(e)
        this.message = e
      })
    }
  }

};
</script>
