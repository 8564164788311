<template>

<v-app>
  <div class="home">

    <div class="main_box" v-if="this.$store.state.email">
      <div>Hi, {{ this.$store.state.email }}</div>
    </div>

    <div class="main_box" v-if="!this.$store.state.email">
      <div>Hello, this is todo list app.</div>
    </div>


  </div>
</v-app>

</template>

<script>


export default {
  name: 'Home',
  components: {
  }
  ,
  mounted () {

    const token = this.$cookies.get('jwt')

    if(token) {
      this.$router.push('/todo', () => {}) 
    } else {
      this.$router.push('/login', () => {}) 
    }

  }
}
</script>

<style scoped>

.main_box {
  width : 80%;
  margin : 50px auto 0 auto;
  padding : 10px;
  /* border: solid 1px #000; */
  border-radius: 5px;
  box-shadow: 0 0 8px gray;
}


</style>
